import "core-js/modules/esnext.iterator.map.js";
import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import CalendarMixin from "@/mixins/Calendar";
import ReviewManagementMixin from "@/mixins/ReviewManagement";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import HasTokens from "@/components/HasTokensReviewManagement.vue";
import CategoriesTable from "@/components/ReviewManagement/CategoriesTable.vue";
export default {
  mixins: [CalendarMixin, ReviewManagementMixin],
  meta: {
    title: "Статистика по категориям"
  },
  data() {
    const localStorageCalendar = JSON.parse(localStorage.getItem("reviewManagementCalendar"));
    if (localStorageCalendar) {
      this.$store.dispatch("reviewManagement/updateCalendar", localStorageCalendar);
    }
    return {
      calendarInternal: null,
      calendar: this.$store.state.reviewManagement.calendar
    };
  },
  methods: {},
  computed: {
    tableKey() {
      return `${this.tabs_tables_model.id}`;
    },
    reportSettings() {
      const tokens = this.selectedTokens.map(item => item.id);
      if (!this.calendar) {
        return null;
      }
      if (!tokens.length) {
        return null;
      }
      const dates = [this.calendar[0], this.calendar[1]];
      return {
        date: dates[0],
        date2: dates[1],
        tokens,
        page_size: 10
      };
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("reviewManagement/updateCalendar", this.calendar);
      localStorage.setItem("reviewManagementCalendar", JSON.stringify(this.calendar));
    }
  },
  components: {
    MainTitle,
    ReportPage,
    HasTokens,
    DataLoading,
    CategoriesTable,
    AppSelectMulti
  }
};